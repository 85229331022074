/* eslint-disable @typescript-eslint/camelcase */
import React, { ChangeEvent, useEffect, useState } from "react";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { Container, FormControl, InputLabel, NativeSelect, Paper, CircularProgress, Button, ButtonGroup, Collapse, IconButton } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import Config from "../config";
import StoreSummary2 from "../components/storeSummary2";
import OfferPerformance from "../components/offerPerformance";
import OfferIncrementality from "../components/offerIncrementality";
import WeekPicker from "../components/weekPicker";
import { createOfferIncrementality, calculateSummary, createOfferPerformance, calculateRawDataAverage } from "../utils";
import { OfferIncrementalityDataSet, OfferPerformanceDataSet, RawData, SummaryData } from "../models";
import { useTranslation } from "react-i18next";
import StoreSummaryPD from "../components/storeSummaryPD";

interface StoreDataSet {
  summary: SummaryData;
  op: OfferPerformanceDataSet;
  oi: OfferIncrementalityDataSet;
  rawData: RawData;
}

const emptyOfferPerformance: OfferPerformanceDataSet = {
  freeRewards: { quantity: 0, averageCheque: 0, attachedSales: 0, fp: 0, gm: 0 },
  pointsOffers: { quantity: 0, averageCheque: 0, attachedSales: 0, fp: 0, gm: 0 },
  valueOffers: { quantity: 0, averageCheque: 0, attachedSales: 0, fp: 0, gm: 0 },
};

const emptyOfferIncrementality: OfferIncrementalityDataSet = {
  cheque: { wOffers: 0, woOffers: 0 },
  traffic: { wOffers: 0, woOffers: 0 },
};

const emptyRawData: RawData = {
  Year: 0,
  WeekOfYear: 0,
  Restaurant: 0,
  AdditionalVisits: 0,
  cust_didnt_use_offers_avg_check: 0,
  cust_didnt_use_offers_avg_freq: 0,
  cust_used_offers_avg_check: 0,
  cust_used_offers_avg_freq: 0,
  cust_used_offers_sum_amt_consolidated: 0,
  cust_used_offers_total_cust_consolidated: 0,
  cust_used_offers_trxns_consolidated: 0,
  DifferenceInWeeklyVisits: 0,
  free_reward_avg_attach_sales: 0,
  free_reward_avg_check_consolidated: 0,
  free_reward_fp_percentage: 0,
  free_reward_sum_amt_consolidated: 0,
  free_reward_sum_trxns_consolidated: 0,
  gm_free_reward: 0,
  gm_points_offer: 0,
  gm_value_offer: 0,
  IncrementalOfferGM: 0,
  IncrementalOfferGMPreviousQuarterWeeklyAverage: 0,
  IncrementalOfferGMQTD: 0,
  IncrementalOfferGMQTDWeeklyAverage: 0,
  IncrementalOfferGMQuarterPreviousYearWeeklyAverage: 0,
  IncrementalOfferSales: 0,
  IncrementalOfferSalesOverTotalSales: 0,
  IncrementalOfferSalesPreviousQuarterWeeklyAverage: 0,
  IncrementalOfferSalesQTD: 0,
  IncrementalOfferSalesQTDWeeklyAverage: 0,
  IncrementalOfferSalesQuarterPreviousYearWeeklyAverage: 0,
  loyalty_pen: 0,
  loyalty_pts_avg_check_consolidated: 0,
  loyalty_pts_sum_amt_consolidated: 0,
  loyalty_pts_trxns_consolidated: 0,
  loyalty_redemption_avg_check_consolidated: 0,
  loyalty_redemption_sum_amt_consolidated: 0,
  loyalty_redemption_trxns_consolidated: 0,
  loyalty_regist_pen: 0,
  LoyaltyTransactions: 0,
  LoyaltyTransactionsOverTransactions: 0,
  NationalIncrementalOfferGM: 0,
  NationalIncrementalOfferSales: 0,
  NationalIncrementalOfferSalesAverage: 0,
  NationalIncrementalOfferSalesOverNationalTotalSales: 0,
  NationalLoyaltyTransactions: 0,
  NationalLoyaltyTransactionsOverNationalTransactions: 0,
  NationalPointsRedemptionGM: 0,
  NationalPointsRedemptionGMAverage: 0,
  NationalPointsRedemptionValue: 0,
  NationalPointsRedemptionValueAverage: 0,
  NationalPointsRedemptionValueOverNationalTotalSales: 0,
  NationalSales: 0,
  NationalTransactions: 0,
  NationalTransactionsWithOffers: 0,
  NationalTransactionsWithOffersOverNationalTransactions: 0,
  non_loyalty_avg_check: 0,
  non_loyalty_trxns: 0,
  non_regist_loyalty_pts_avg_check: 0,
  non_regist_loyalty_pts_avg_freq: 0,
  non_regist_loyalty_pts_sum_amt: 0,
  non_regist_loyalty_pts_trxns: 0,
  non_regist_loyalty_redemption_avg_check: 0,
  non_regist_loyalty_redemption_avg_freq: 0,
  non_regist_loyalty_redemption_sum_amt: 0,
  non_regist_loyalty_redemption_trxns: 0,
  offer_pen: 0,
  OfferGuests: 0,
  PointsRedemptionGM: 0,
  PointsRedemptionGMPreviousQuarterWeeklyAverage: 0,
  PointsRedemptionGMQTD: 0,
  PointsRedemptionGMQTDWeeklyAverage: 0,
  PointsRedemptionGMQuarterPreviousYearWeeklyAverage: 0,
  PointsRedemptionValue: 0,
  PointsRedemptionValueOverTotalSales: 0,
  PointsRedemptionValuePreviousQuarterWeeklyAverage: 0,
  PointsRedemptionValueQTD: 0,
  PointsRedemptionValueQTDWeeklyAverage: 0,
  PointsRedemptionValueQuarterPreviousYearWeeklyAverage: 0,
  RegionalLoyaltyTransactions: 0,
  RegionalLoyaltyTransactionsOverRegionalTransactions: 0,
  RegionalTransactions: 0,
  RegionalTransactionsWithOffers: 0,
  RegionalTransactionsWithOffersOverRegionalTransactions: 0,
  regist_loyalty_pts_avg_check: 0,
  regist_loyalty_pts_avg_freq: 0,
  regist_loyalty_pts_sum_amt: 0,
  regist_loyalty_pts_trxns: 0,
  regist_loyalty_redemption_avg_check: 0,
  regist_loyalty_redemption_avg_freq: 0,
  regist_loyalty_redemption_sum_amt: 0,
  regist_loyalty_redemption_trxns: 0,
  regist_points_offer_avg_check: 0,
  regist_points_offer_avg_freq: 0,
  regist_points_offer_fp_percentage: 0,
  regist_points_offer_total_cust: 0,
  regist_points_offer_trxns: 0,
  regist_value_offer_avg_check: 0,
  regist_value_offer_avg_freq: 0,
  regist_value_offer_fp_percentage: 0,
  regist_value_offer_total_cust: 0,
  regist_value_offer_trxns: 0,
  Sales: 0,
  SpendIncreaseOfOfferGuestsOverNonOfferGuests: 0,
  total_loyalty_trxns: 0,
  total_offer_trxns: 0,
  total_regist_trxns: 0,
  total_trxns: 0,
  Transactions: 0,
  TransactionsWithOffers: 0,
  TransactionsWithOffersOverTransactions: 0,
  value_offer_avg_attach_sales: 0,
};

const emptySummary: SummaryData = {
  salesIncrease: 0,
  salesIncreaseFromOffers: 0,
  blendedGM: 0,
  blendedFP: 0,
  loyaltyPenetration: 0,
  registeredLoyalty: 0,
  offerPenetration: 0,
};

const trailingWeeks = 4;
const minDate = moment("2020-07-27");

const Store: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const maxDate = moment().startOf("week");

  const { storeId, date } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [storeIds, setStoreIds] = useState<string[]>([]);
  const [selectedStoreId, setSelectedStoreId] = useState(storeId);
  const [selectedDate, setSelectedDate] = useState((date ? moment(date) : maxDate).startOf("week"));
  const [hasPast, setHasPast] = useState(true);
  const [hasFuture, setHasFuture] = useState(false);
  const [lastFetch, setLastFetch] = useState("");
  const [pdExpanded, setPdExpanded] = useState(false);

  const handleStoreChange = (event: ChangeEvent<{ value: unknown }>): void => {
    const selectedStoreId = event.target.value as string;
    setSelectedStoreId(selectedStoreId);
    navigate(`/store/${selectedStoreId}/${selectedDate.format("YYYYMMDD")}`);
  };
  const handleDateChange = (date: MaterialUiPickersDate): void => {
    const startOfWeek = moment(date).startOf("week");
    setSelectedDate(startOfWeek);
    navigate(`/store/${selectedStoreId}/${startOfWeek.format("YYYYMMDD")}`);
  };
  const changeWeek = (offset: number): void => {
    const newDate = selectedDate.clone().add(offset, "w");
    setSelectedDate(newDate);
    navigate(`/store/${selectedStoreId}/${newDate.format("YYYYMMDD")}`);
  };
  const togglePdExpanded = (): void => {
    setPdExpanded(!pdExpanded);
  };

  const [storeData, setStoreData] = useState({
    current: {
      summary: emptySummary,
      offerPerformance: emptyOfferPerformance,
      offerIncrementality: emptyOfferIncrementality,
      rawData: emptyRawData,
    },
    previous: {
      summary: emptySummary,
      offerPerformance: emptyOfferPerformance,
      offerIncrementality: emptyOfferIncrementality,
    },
  });

  useEffect(() => {
    const endDate = selectedDate.format("YYYYWW");
    const startDate = selectedDate.clone().subtract(trailingWeeks, "w").format("YYYYWW");
    const url = `${Config.apiUrl}/restaurant?storeId=${selectedStoreId}&startDate=${startDate}&endDate=${endDate}`;
    if (lastFetch === url) {
      return;
    }
    setLoading(true);
    setLastFetch(url);
    setHasPast(selectedDate > minDate);
    setHasFuture(selectedDate < moment().startOf("week").subtract(1, "d").startOf("week"));

    axios
      .get<{ [storeId: string]: RawData[] }>(url)
      .then(({ data }) => {
        if (lastFetch === "") {
          const newStoreIds = Object.keys(data);
          setStoreIds(newStoreIds);

          if (!storeId) {
            navigate(`/store/${newStoreIds[0]}/${selectedDate.format("YYYYMMDD")}`);
            setSelectedStoreId(newStoreIds[0]);
            return;
          }
        }

        const dataForStore = data[selectedStoreId];
        if (!dataForStore || !dataForStore.length) {
          throw new Error("dataNotFound");
        }

        const current = dataForStore.pop();
        if (!current || current.reportDate !== parseInt(endDate)) {
          throw new Error("dataNotFound");
        }
        const currentOP = createOfferPerformance(current);
        const currentOI = createOfferIncrementality(currentOP, current);

        const newStoreData = {
          current: {
            summary: calculateSummary(currentOP, currentOI, current),
            offerPerformance: currentOP,
            offerIncrementality: currentOI,
            rawData: current,
          },
          previous: {
            summary: emptySummary,
            offerPerformance: emptyOfferPerformance,
            offerIncrementality: emptyOfferIncrementality,
          },
        };

        if (dataForStore.length !== 0) {
          const previous = calculateRawDataAverage(dataForStore);
          const previousOP = createOfferPerformance(previous);
          const previousOI = createOfferIncrementality(previousOP, previous);
          newStoreData.previous = {
            summary: calculateSummary(previousOP, previousOI, previous),
            offerPerformance: previousOP,
            offerIncrementality: previousOI,
          };
        }

        setStoreData(newStoreData);
        setErrorMessage("");
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setLoading(false);
      });
  }, [lastFetch, storeId, selectedStoreId, selectedDate, navigate]);
  return (
    <Container className="page-store">
      <div className="selectors">
        <FormControl id="store-select">
          <InputLabel id="store-select-label">{t("storeSelectorLabel")}</InputLabel>
          {storeIds.length !== 0 && (
            <NativeSelect id="store-select" value={selectedStoreId || ""} onChange={handleStoreChange}>
              {storeIds.map((storeId) => (
                <option key={storeId} value={storeId}>
                  {storeId}
                </option>
              ))}
            </NativeSelect>
          )}
        </FormControl>
        <WeekPicker
          label={t("weekSelectorLabel")}
          className="selector-week"
          value={selectedDate}
          minDate={minDate}
          disableFuture={true}
          format={t("weekSelectorFormat")}
          onChange={handleDateChange}
        />
        <ButtonGroup className="buttons-navigate" size="small">
          <Button aria-label={t("previousWeek")} onClick={(): void => changeWeek(-1)} disabled={!hasPast}>
            <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
            </svg>
          </Button>
          <Button aria-label={t("nextWeek")} onClick={(): void => changeWeek(1)} disabled={!hasFuture}>
            <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
            </svg>
          </Button>
        </ButtonGroup>
      </div>

      {loading && <CircularProgress className="loader" />}
      {errorMessage ? (
        <Paper elevation={5} className="container-error">
          {t(errorMessage)}
        </Paper>
      ) : (
        <>
          <Paper elevation={5} className="container-table" id="container-summary2">
            <StoreSummary2 data={storeData.current.rawData} />
          </Paper>
          {/* <Paper elevation={5} className="container-table">
            <h3>{t<string>("summaryHeading")}</h3>
            <StoreSummary data={storeData.current.summary} previousData={storeData.previous.summary} trailingWeeks={trailingWeeks} />
          </Paper> */}
          <Paper elevation={5} className="container-table" onClick={pdExpanded ? undefined : togglePdExpanded}>
            <h3>{t<string>("performanceDetailsHeading")}</h3>
            <IconButton
              className={"button-expand icon-expand" + (pdExpanded ? " expanded" : "")}
              onClick={togglePdExpanded}
              aria-expanded={pdExpanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
            <Collapse in={pdExpanded}>
              <OfferPerformance data={storeData.current.offerPerformance} />
              <OfferIncrementality
                data={storeData.current.offerIncrementality}
                previousData={storeData.previous.offerIncrementality}
                trailingWeeks={trailingWeeks}
              />
              <StoreSummaryPD data={storeData.current.rawData} />
            </Collapse>
          </Paper>
        </>
      )}
    </Container>
  );
};

export default Store;
